<template>
  <v-card>
    <v-card-text>
      <Substipulator
        :contractId="$route.params.id"
        @sub="subSelected($event)"
        v-if="hasSub"
      />

      <template v-if="contract.situation !== 'Cancelado'">
        <v-row
          dense
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        >
          <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
            <BaseButton
              id="btn-create"
              name="btn-create"
              color="secondary"
              title="Novo cadastro"
              icon="fa-solid fa-plus"
              height="40"
              @click="create"
              :disabled="
                progress === 100 || count.amountLeaveBeneficiaries === 0
              "
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-text>
      <BaseTableList title="Listagem de beneficiários" :subtitle="true">
        <template v-slot:subtitle>
          Falta
          <b>
            {{
              count.amountLeaveBeneficiaries -
              count.amountRegisteredLeaveBeneficiaries
            }}
          </b>
          de
          <b>{{ count.amountLeaveBeneficiaries }}</b>
        </template>
        <template v-slot:table>
          <v-progress-linear
            class="b_list__card-table__progress"
            v-model="progress"
            color="success"
            height="15"
          >
            <template v-slot:default="{ value }">
              {{ Math.ceil(value) }}%
            </template>
          </v-progress-linear>
          <v-data-table
            dense
            disable-sort
            item-key="id"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalRecords"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100],
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'chevron_left',
              nextIcon: 'chevron_right'
            }"
          >
            <template v-slot:[`item.document`]="{ item }">
              {{ formatCpf(item.document) }}
            </template>

            <template v-slot:[`item.birthDate`]="{ item }">
              {{ formatDate(item.birthDate) }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="success"
                    class="mr-2"
                    small
                    @click="editItem(item)"
                  >
                    fa-solid fa-pencil
                  </v-icon>
                </template>
                <span>Visualizar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="error"
                    small
                    @click="deleteItem(item)"
                    v-if="contract.situation !== 'Cancelado'"
                    v-permission="{
                      roles: [
                        'A1',
                        'AC1',
                        'AC2',
                        'AC3',
                        'ACI1',
                        'ACI2',
                        'ACI3'
                      ],
                      operator: '==='
                    }"
                  >
                    fa-regular fa-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </template>
      </BaseTableList>
    </v-card-text>

    <v-card-actions class="pa-4">
      <v-spacer />
      <BaseButton
        id="btn-back"
        name="btn-back"
        outlined
        color="primary"
        title="Voltar"
        @click="back"
      />
    </v-card-actions>

    <DialogLeaveBeneficiary
      v-if="dialog"
      :customerId="substipulator ? substipulator : customerId"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />
  </v-card>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { formatDate, formatCpf } from '@/helpers/formatting';
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    DialogLeaveBeneficiary: () => import('./dialogs/dialog-leave-beneficiary'),
    Substipulator: () => import('./commons/substipulator')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    substipulator: null,
    progress: 0,
    dialog: false,
    dialogTitle: '',
    dialogEdit: false,
    selectItem: {},
    options: {},
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'CPF', value: 'document' },
      { text: 'Data de nascimento', value: 'birthDate' },
      { text: '', value: 'action' }
    ],
    count: {
      amountLeaveBeneficiaries: 0,
      amountRegisteredLeaveBeneficiaries: 0
    }
  }),

  created() {
    this.searchCount();
  },

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  methods: {
    formatDate,
    formatCpf,

    async deleteItem(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.deleteLeaveBeneficiaries(
            item.id
          );

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
            this.searchCount();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const contractService = new ContractService();
        const { status, data } = await contractService.getLeaveBeneficiaries({
          page,
          take: itemsPerPage,
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async searchCount() {
      try {
        const contractService = new ContractService();
        const { status, data } =
          await contractService.getLeaveBeneficiariesCount({
            contractId: this.$route.params.id,
            customerId: this.substipulator
              ? this.substipulator
              : this.customerId
          });

        if (status === 200) {
          this.count = data;
          this.calculateProgress(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    calculateProgress(data) {
      if (
        data.amountRegisteredLeaveBeneficiaries > 0 &&
        data.amountLeaveBeneficiaries > 0
      ) {
        this.progress =
          (data.amountRegisteredLeaveBeneficiaries /
            data.amountLeaveBeneficiaries) *
          100;
      } else {
        this.progress = 0;
      }
    },

    back() {
      this.$emit('back');
    },

    create() {
      this.dialog = true;
      this.dialogTitle = 'Adicionar beneficiário afastado';
      this.dialogEdit = false;
      this.selectItem = {};
    },

    editItem(item) {
      this.dialog = true;
      this.dialogTitle = 'Visualizar beneficiário afastado';
      this.dialogEdit = true;
      this.selectItem = Object.assign({}, item);
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogTitle = '';
      this.dialogEdit = false;
      this.selectItem = {};

      if (search) {
        this.search();
        this.searchCount();
      }
    },

    subSelected(event) {
      this.substipulator = event;
      this.search();
      this.searchCount();
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
