var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[(_vm.hasSub)?_c('Substipulator',{attrs:{"contractId":_vm.$route.params.id},on:{"sub":function($event){return _vm.subSelected($event)}}}):_vm._e(),(_vm.contract.situation !== 'Cancelado')?[_c('v-row',{directives:[{name:"permission",rawName:"v-permission",value:({
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }),expression:"{\n          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n          operator: '==='\n        }"}],attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('BaseButton',{attrs:{"id":"btn-create","name":"btn-create","color":"secondary","title":"Novo cadastro","icon":"fa-solid fa-plus","height":"40","disabled":_vm.progress === 100 || _vm.count.amountLeaveBeneficiaries === 0},on:{"click":_vm.create}})],1)],1)]:_vm._e()],2),_c('v-card-text',[_c('BaseTableList',{attrs:{"title":"Listagem de beneficiários","subtitle":true},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Falta "),_c('b',[_vm._v(" "+_vm._s(_vm.count.amountLeaveBeneficiaries - _vm.count.amountRegisteredLeaveBeneficiaries)+" ")]),_vm._v(" de "),_c('b',[_vm._v(_vm._s(_vm.count.amountLeaveBeneficiaries))])]},proxy:true},{key:"table",fn:function(){return [_c('v-progress-linear',{staticClass:"b_list__card-table__progress",attrs:{"color":"success","height":"15"},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_vm._v(" "+_vm._s(Math.ceil(value))+"% ")]}}]),model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.document`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCpf(item.document))+" ")]}},{key:`item.birthDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.birthDate))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.contract.situation !== 'Cancelado')?_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                    roles: [
                      'A1',
                      'AC1',
                      'AC2',
                      'AC3',
                      'ACI1',
                      'ACI2',
                      'ACI3'
                    ],
                    operator: '==='
                  }),expression:"{\n                    roles: [\n                      'A1',\n                      'AC1',\n                      'AC2',\n                      'AC3',\n                      'ACI1',\n                      'ACI2',\n                      'ACI3'\n                    ],\n                    operator: '==='\n                  }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","outlined":"","color":"primary","title":"Voltar"},on:{"click":_vm.back}})],1),(_vm.dialog)?_c('DialogLeaveBeneficiary',{attrs:{"customerId":_vm.substipulator ? _vm.substipulator : _vm.customerId,"dialog":_vm.dialog,"title":_vm.dialogTitle,"edit":_vm.dialogEdit,"selectItem":_vm.selectItem},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }